import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import StepBar from "../components/base/step-bar";
import Pane from "../components/base/pane";
import { Subheading, Heading } from "../components/base/heading";

const day1 = [
    {
        label: "Start",
        start: new Date("2024-09-26 10:00"),
        end: new Date("2024-09-26 10:00"),
        description: "",
        audience: "",
    },
    {
        label: ".git (Wolfi)",
        start: new Date("2024-09-26 11:00"),
        end: new Date("2024-09-26 12:00"),
        description: "Was macht git eigentlich hinter dem Vorhang?",
        audience: "Service & Dev -- Kenntnisse über grundlegende Bedienung vorrausgesetzt",
    },
    {
        label: "Brotzeit Start",
        start: new Date("2024-09-27 12:00"),
        end: new Date("2024-09-27 12:00"),
        description: "",
        audience: "",
    },
    {
        label: "Grillen",
        start: new Date("2024-09-26 18:00"),
        end: new Date("2024-09-26 23:59"),
        description: "",
        audience: "",
    },
];

const day2 = [
    {
        label: "Start",
        start: new Date("2024-09-27 10:00"),
        end: new Date("2024-09-27 10:00"),
        description: "",
        audience: "",
    },
    {
        label: "Food",
        start: new Date("2024-09-27 12:00"),
        end: new Date("2024-09-27 12:00"),
        description: "",
        audience: "",
    },
    {
        label: "Feedback discussion (Katharina)",
        start: new Date("2024-09-27 13:00"),
        end: new Date("2024-09-27 14:00"),
        description: "Presentation and short discussion of the survey",
        audience: "Everyone",
    },
    {
        label: "End",
        start: new Date("2024-09-27 18:00"),
        end: new Date("2024-09-27 18:00"),
        description: "",
        audience: "",
    },
];

/**
 * The properties for {@link Index}
 */
export type IndexProps = {};

/**
 * The index site
 */
export default function Index(props: IndexProps) {
    return (
        <div className={"my-6 flex w-full items-center justify-center p-2 sm:my-12"}>
            <div className={"flex flex-col gap-6"}>
                <Pane className={"justify-center"}>
                    <Heading>Truffledays - September</Heading>
                </Pane>
                <Pane>
                    <div className={"grid w-full gap-12 sm:gap-24 md:grid-cols-2"}>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 1</Subheading>
                            <StepBar
                                steps={day1.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    audience: s.audience,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 2</Subheading>
                            <StepBar
                                steps={day2.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    audience: s.audience,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                    </div>
                </Pane>
            </div>
        </div>
    );
}

export const Route = createFileRoute("/")({
    component: Index,
});
